import tw, { styled } from "twin.macro"

import { StyledSelectWrapper } from "../../Styled/Form"

export const Desktop = tw.div`
  hidden md:flex flex-auto justify-start
`

export const Mobile = styled(StyledSelectWrapper)`
  ${tw`md:hidden mb-1-2 uppercase`}
`

export const Filter = styled.button`
  ${tw`mr-0-6 rounded-50 text-14 leading-none px-1-6 py-1 border uppercase`}
  ${({ active }) => (active ? tw`bg-navy text-white` : tw`bg-white text-navy`)}
`
