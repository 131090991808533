import React from "react"

import { getAdditionalText } from "../../../utils/getAdditionalText"

export const withFilters = Component => ({
  name = "Filters",
  filters,
  activeFilter,
  handleFilterChange,
}) => {
  const {
    page: {
      articles: { additionalFilterText },
    },
  } = getAdditionalText()

  Component.displayName = name
  return (
    <Component
      filters={filters}
      additionalFilterText={additionalFilterText}
      activeFilter={activeFilter}
      handleFilterChange={handleFilterChange}
    />
  )
}
