import React, { useState } from "react"

import { useArticles } from "../../hooks/useArticles"
import { getAdditionalText } from "../../utils/getAdditionalText"

export const withArticles = Component => ({
  name = "Articles",
  page,
  articles,
}) => {
  const { title, featuredArticle, size } = page || {}
  const { totalCount } = articles
  const {
    page: {
      articles: { additionalPageSizeText },
    },
  } = getAdditionalText()



  const [currentPageSize, setCurrentPageSize] = useState(size[0])

  const {
    items,
    availableFilters,
    activeFilter,
    activeSortBy,
    currentPage,
    handleFilterChange,
    handleSortByChange,
    handlePageChange,
  } = useArticles(currentPageSize)

  const totalPageCount = Math.ceil(totalCount / currentPageSize)

  const handleSelectedSizeChange = sizeValue => {
    setCurrentPageSize(sizeValue)
  }

  Component.displayName = name


  return (
    <Component
      title={title}
      articles={items}
      filters={availableFilters}
      featuredArticle={featuredArticle}
      activeFilter={activeFilter}
      handleFilterChange={handleFilterChange}
      activeSortBy={activeSortBy}
      handleSortByChange={handleSortByChange}
      currentPage={currentPage}
      handlePageChange={handlePageChange}
      totalPageCount={totalPageCount}
      currentPageSize={currentPageSize}
      handleSelectedSizeChange={handleSelectedSizeChange}
      additionalPageSizeText={additionalPageSizeText}
      size={size}
    />
  )
}
