import React from "react"
import { getAdditionalText } from "../../../utils/getAdditionalText"

export const withSort = Component => ({
  name = "Sort",
  activeSortBy,
  handleSortByChange,
}) => {
  const {
    page: {
      articles: {
        additionalSortByText,
        additionalSortByPublishDateDescText,
        additionalSortByPublishDateAscText,
      },
    },
  } = getAdditionalText()

  Component.displayName = name
  return (
    <Component
      additionalSortByText={additionalSortByText}
      activeSortBy={activeSortBy}
      handleSortByChange={handleSortByChange}
      additionalSortByPublishDateDescText={additionalSortByPublishDateDescText}
      additionalSortByPublishDateAscText={additionalSortByPublishDateAscText}
    />
  )
}
