import React from "react"

import { withArticles } from "./withArticles"
import {
  Title,
  Control,
  Divider,
  List,
  Wrapper,
  Empty,
  FeaturedArticle,
  PaginationWrapper,
  PageSizeWrapper,
} from "./ArticlesStyle"
import { Filters } from "./Filters/Filters"
import { Sort } from "./Sort/Sort"
import { Card } from "./Card/Card"
import { Pagination } from "../Pagination/Pagination"
import { PageSize } from "../PageSize/PageSize"

export const Articles = withArticles(
  ({
    title,
    articles,
    filters,
    featuredArticle,
    activeFilter,
    handleFilterChange,
    activeSortBy,
    handleSortByChange,
    currentPage,
    handlePageChange,
    totalPageCount,
    currentPageSize,
    handleSelectedSizeChange,
    additionalPageSizeText,
    size,
  }) => (
    <>
      <FeaturedArticle card={featuredArticle} />
      <Wrapper>
        <Title>{title}</Title>
        <Control>
          <div>
            <Filters
              filters={filters}
              activeFilter={activeFilter}
              handleFilterChange={handleFilterChange}
            />
          </div>
          <Sort
            activeSortBy={activeSortBy}
            handleSortByChange={handleSortByChange}
          />
        </Control>
        <Divider />
        {articles?.length > 0 ? (
          <>
            <List>
              {articles.map(article => (
                <Card key={article._id} item={article} />
              ))}
            </List>
            <PaginationWrapper>
              <PageSizeWrapper>
                <PageSize
                  selectedSize={currentPageSize}
                  handleSelectedSizeChange={handleSelectedSizeChange}
                  sizeOptions={size}
                  text={additionalPageSizeText}
                />
              </PageSizeWrapper>
              <div>
                <Pagination
                  totalPageCount={totalPageCount}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                />
              </div>
            </PaginationWrapper>
          </>
        ) : (
          <Empty>No articles</Empty>
        )}
      </Wrapper>
    </>
  ),
)
