import tw, { styled } from "twin.macro"
import { HeroCard } from "../Sections/Hero/Card/HeroCard"

export const FeaturedArticle = styled(HeroCard)`
  ${tw`hidden md:block`}
`

export const Wrapper = tw.div`
  max-w-xl mx-auto md:px-7-5 px-2-4 pb-4 md:pb-8
`

export const Title = tw.h1`
  font-bold text-28 md:text-35 leading-1.28 tracking-10 py-3-2 md:pt-6 md:pb-2-4 text-center md:text-left uppercase
`

export const Control = tw.div`
  flex mb-3-2 md:mb-4-8 flex-col md:flex-row md:justify-between
`

export const Divider = tw.hr`text-navy text-opacity-20 mb-3-2`

export const List = tw.div`grid grid-cols-1 md:grid-cols-3 gap-x-3 gap-y-4-6 md:gap-y-6-4 mb-6-4`

export const Empty = tw.p`text-center py-2-4`

export const PaginationWrapper = tw.div`
  flex flex-col md:flex-row items-center justify-between
`

export const PageSizeWrapper = tw.div`
  hidden md:block
`
